import { ProvidedPatientDetails } from "../api/patient/PatientDTOs";
import { NhsLoginType } from "../api/submission";

export const getNhsLoginType = (
    isNhsApp: boolean,
    providedDetails: ProvidedPatientDetails | null,
): NhsLoginType => {
    if (isNhsApp && navigator.userAgent.includes("nhsapp")) {
        return "nhsApp";
    }

    return providedDetails ? "nhsLogin" : null;
};
